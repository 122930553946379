<template>
  <div class="projectSelectUser">
    <el-container>
      <div v-if="userDeptType === 'all'" class="UserDeptTree">
        <tree-layout
          treeTitle="组织架构"
          :treeLoading="treeLoading"
          :tree-options="treeOptions"
          @tree-node-click="getNodeClick"
          @include-down="includeDownAll"
          style="margin-right: 5px"
        >
        </tree-layout>
      </div>
      <div v-else>
        <CommonTreeNew
          ref="commonTree"
          class="UserDeptTree"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)"
          :isShowdig="false"
          :showCheckbox="false"
          :popover-show="false"
          @getNodeClick="getNodeClick"
          @include-down="includeDown"
          :urlParmas="{
            tenantId: '',
            parentId: userInfo.dept_id,
            ...treeParams,
          }"
        ></CommonTreeNew>
      </div>
      <el-container>
        <el-main style="width: 50%" class="margin-r-10">
          <head-layout
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
          >
          </head-layout>
          <grid-head-layout
            ref="searchFrom"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            v-model="searchForm"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          >
          </grid-head-layout>
          <el-table
            border
            v-loading="tableLoading"
            :data="tableData"
            stripe
            ref="multipleTable"
            @selection-change="selectionChange"
            :height="447"
            rowKey="id"
          >
            <el-table-column
              v-if="multiple"
              reserve-selection
              :selectable="selectable"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              :key="index"
              v-for="(item, index) in tableOptions.column"
              :width="item.width"
              :show-overflow-tooltip="item.overHidden"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="{ row, $index }">
                <span
                  v-html="
                    item.formatter
                      ? item.formatter(row, $index)
                      : row[item.prop]
                  "
                ></span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="!multiple"
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="{row}">
                <el-button type="text" @click="roleDefBtn(row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="margin-t-20"
            background
            @size-change="onLoad"
            @current-change="onLoad"
            :current-page.sync="page.currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size.sync="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </el-main>
        <el-main style="width: 50%" class="border-left" v-if="multiple">
          <head-layout head-title="已选用户" class="margin-b-14"></head-layout>
          <el-table
            style="margin-top: 48px"
            border
            :data="selectData"
            stripe
            :height="490"
            rowKey="id"
          >
            <el-table-column
              :key="index"
              v-for="(item, index) in tableOptions.column"
              :width="item.width"
              :show-overflow-tooltip="item.overHidden"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="{ row, $index }">
                <span
                  v-html="
                    item.formatter
                      ? item.formatter(row, $index)
                      : row[item.prop]
                  "
                ></span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ $index, row }">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete($index, row)"
                >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
    <div
      class="flex-container flex-jus-e footerBtn">
      <el-button
        :loading="loading"
        :disabled="loading"
        size="mini"
        @click="closeDialog"
      >取消</el-button
      >
      <el-button
        :loading="loading"
        :disabled="loading"
        size="mini"
        type="primary"
        @click="confirm"
      >确认</el-button
      >
    </div>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import {
  getDeptLazyTreeAll,
} from "@/api/system/dept";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index";
import { mapGetters } from "vuex";
import {prjthirduserPage} from "@/api/safe/itemList";

export default {
  name: "user",
  components: {
    HeadLayout,
    CommonTree,
  },
  props: {
    dataCallBack: Function,
    deptCategory: [],
    userDeptType: {
      type: String,
      default: "",
    },
    publicParams: {
      type: Object,
      default: {},
    },
    treeParams: {
      type: Object,
      default: {},
    },
    //设置默认选中
    checkedData: {
      type: Array,
      default: [],
    },
    multiple : {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      loading: false,
      checkedList:[],
      selectData:[],
      treeLoading: true,
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.defBtn"),
          emit: "role-defBtn",
          type: "text",
          icon: "",
        },
      ],
      tableData: [],
      tableLoading: true,
      search: {},
      selectionList: [],
      query: {},
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      props: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      treeData: [],
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "userName",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          label: "用户类型",
          prop: "personnelType",
          type: "select",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicData: [],
          value: "1",
          multiple: true,
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type",
          span: 4,
          placeholder: "请选择用户类型",
        },{
          label: "",
          span: 4,
          placeholder: "请选择岗位",
          prop: "stPostId",
          type: "tree",
          dicUrl:`/api/sinoma-hse-prj/sinoma-hse-prj/stpost/typeTree?organizationId=${this.userInfo.dept_id}`,
          dicFormatter: (res) => {
            return this.setDisabled(res)
          },
          props: {
            label: 'postName',
            value: 'id',
          },
        },
      ];
    },
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTreeAll(parentId).then((res) => {
            resolve(
              res.data.data.map((item) => {
                that.treeLoading = false;
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      };
    },
    tableOptions() {
      return {
        customRowAddBtn: false,
        menu: true,
        selection: false,
        column: [
          {
            label: "用户账号",
            prop: "account",
            align: "left",
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "userName",
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: '所属组织',
            prop: "deptName",
            align: "left",
            overHidden: true,
          },
          {
            label: "岗位",
            placeholder: "请选择岗位",
            prop: "stPostName",
          },
        ]
      }
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.checkedList = this.checkedData.map((item) => item.userId);
  },
  methods: {
    //清除人员
    closeDialog() {
      this.loading = false;
      this.$emit("closeDia");
    },
    //请选择人员
    confirm() {
      this.loading = true;
      if (this.closeCallBack) {
        this.$emit("select-data", this.selectData,this.treeDeptId,this.treeDeptName,{
          loading: () => {
            this.loading = false;
          },
          done: () => {
            this.closeDialog();
          },
        });

      } else {
        this.$emit("select-data", this.selectData,this.treeDeptId,this.treeDeptName);
        this.closeDialog();
      }
    },
    setDisabled(tree){
      tree.forEach(item => {
        if (item.children) {
          this.setDisabled(item.children)
        }
        item.disabled=item.postType == 2 ? false : true;
      })
      return tree
    },
    roleDefBtn(row) {
      this.$emit("select-data", row, this.treeDeptId, this.treeDeptName);
    },
    handleDelete(index, row) {
      if (this.checkedList.includes(row.id)) {
        return this.$message.error('当前人是默认选中人，不允许删除')
      }
      this.selectData.splice(index, 1);
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.treeDeptName = data.title;
      this.page.currentPage = 1;
      this.onLoad();
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.searchForm.isInclude = e;
      this.onLoad();
    },

    includeDownAll(e) {
      this.page.currentPage = 1;
      this.searchForm.isInclude = e;
      this.onLoad();
    },

    searchReset(data) {
      this.onLoad();
    },
    searchChange() {

      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    selectionChange(selection, row) {
      selection.forEach((item) => {
        item.treeDeptId = this.treeDeptId;
        item.treeDeptName = this.treeDeptName;
      });
      this.selectData = selection;
    },
    selectable (row) {
      return !this.checkedList.includes(row.id)
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(params = {}) {
      this.param = params;
      this.tableLoading = true;
      let query={
        current: this.page.currentPage,
        size: this.page.pageSize,
        deptId: this.treeDeptId,
        ...params,...this.publicParams, ...this.searchForm,
      }
      prjthirduserPage(query).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableLoading = false;
        this.tableData = typeof this.dataCallBack == "function" ? this.dataCallBack(data.records) : data.records;
        this.$nextTick(() => {
          this.tableData.map((item, index) => {
            if (this.checkedList.includes(item.id)) {
              this.$refs.multipleTable.toggleRowSelection(item,true);
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectSelectUser{
  .el-scrollbar {
    height: 100%;
  }

  .box .el-scrollbar__wrap {
    overflow: scroll;
  }

  .footerBtn{
    border-top: 1px solid #cccccc;
    padding-top: 10px;
  }
  ::v-deep  .el-tree {
    height: 490px !important;
    max-height: 490px !important;
  }
}

</style>
