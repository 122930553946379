var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "headTitle" },
        [
          _vm.title
            ? _c("head-layout", {
                attrs: {
                  "is-show-tip": _vm.isShowTip,
                  "head-title": _vm.title,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex-container" },
            [
              _vm.updateName === "updateName"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { size: "small" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeSuccess,
                        "on-success": _vm.uploadSuccess,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemoveFile,
                        "http-request": _vm.handleHttpRequest,
                        "on-exceed": _vm.handleExceed,
                        multiple: "",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayOut",
        staticClass: "fileTable",
        class: _vm.tableData.length == 0 ? "noFile" : "",
        attrs: {
          "head-title": "文件上传",
          "table-options": _vm.option,
          "table-data": _vm.tableData,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.rowView,
          "row-download": _vm.rowDownload,
          "row-del": _vm.rowDel,
          "row-pause": _vm.rowPause,
        },
      }),
      _c("fileView", { ref: "fileView", attrs: { title: "预览" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }